import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Article from '../components/Resources/Detail/Article';
import Event from '../components/Resources/Detail/Event';
import Podcast from '../components/Resources/Detail/Podcast';
import Video from '../components/Resources/Detail/Video';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const PostTemplate = ({location, data: {post}}) => {
  const resourceType = post.resourceTypes.nodes.length ? post.resourceTypes.nodes[0].slug : null;
  let DetailComponent;

  switch (resourceType) {
    case 'articles':
    case 'client-news':
    case 'thought-leadership':
    case 'case-studies':
    case 'firm-news':
    case 'press-releases':
      DetailComponent = <Article {...post} locationData={location} />;
      break;

    case 'events':
      DetailComponent = <Event {...post} locationData={location} />;
      break;

    case 'podcasts':
      DetailComponent = <Podcast {...post} locationData={location} />;
      break;

    case 'videos':
      DetailComponent = <Video {...post} locationData={location} />;
      break;

    default:
      DetailComponent = <Article {...post} locationData={location} />;
  }

  return (
    <Layout>
        <Seo post={post} />
      {DetailComponent}
    </Layout>
  )
}

export default PostTemplate

export const postQuery = graphql`
    fragment ArticleImageData on File {
        childImageSharp {
          id
          gatsbyImageData(
              layout: CONSTRAINED,
              aspectRatio: 1.3,
              width: 663
          )
        }
    }
    fragment EventImageData on File {
        childImageSharp {
            id
            gatsbyImageData(
                layout: CONSTRAINED,
                aspectRatio: 1.52,
                width: 770
            )
        }
    }
    fragment PodcastImageData on File {
        childImageSharp {
            id
            gatsbyImageData(
                layout: CONSTRAINED,
                aspectRatio: 1,
                width: 550
            )
        }
    }
    fragment VideoImageData on File {
        childImageSharp {
            id
            gatsbyImageData(
                layout: CONSTRAINED,
                aspectRatio: 1.77,
                width: 1076
            )
        }
    }
    query PostById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
        $isArticle: Boolean!
        $isEvent: Boolean!
        $isVideo: Boolean!
        $isPodcast: Boolean!
    ) {
        # selecting the current post by id
        post: wpPost(id: { eq: $id }) {
            id
            content
            title
            date(formatString: "MMMM DD, YYYY")
            machineDate: date(formatString: "YYYY-MM-DD")
            seo {
                ...SeoFields
            }
            locale {
                id
                locale
            }
            translations {
                id
                locale
                href
            }
            resourceTypes {
                nodes {
                    slug
                    name
                }
            }
            categories {
                nodes {
                    name
                    uri
                }
            }
            featuredImage {
                node {
                    altText
                    articleImage: localFile @include(if:$isArticle) {
                        ...ArticleImageData
                    }

                    eventImage: localFile @include(if:$isEvent) {
                        ...EventImageData
                    }

                    podcastImage: localFile @include(if:$isPodcast) {
                        ...PodcastImageData
                    }
                    videoImage: localFile @include(if:$isVideo) {
                        ...VideoImageData
                    }
                }
            }
            resourceInformation {
                pdf {
                    localFile {
                        publicURL
                    }
                }
                service {
                    ... on WpService {
                        title
                        uri
                        slug
                        serviceTypes {
                            nodes {
                                slug
                                name
                            }
                        }
                    }
                }
                author {
                    ... on WpPost_Resourceinformation_Author_SystemAuthor {
                        fieldGroupName
                        authorSystemPeopleBio
                        authorSystemPeople {
                            ... on WpPeople {
                                title
                                uri
                                peopleContent {
                                    summary
                                }
                                featuredImage {
                                    node {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    layout: CONSTRAINED,
                                                    aspectRatio: 1,
                                                    width: 70
                                                )
                                            }
                                        }
                                    }
                                }
                                jobTitles {
                                    nodes {
                                        name
                                        id
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPost_Resourceinformation_Author_CustomAuthor {
                        fieldGroupName
                        name
                        bio
                        authorImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED,
                                        aspectRatio: 1,
                                        width: 70
                                    )
                                }
                            }
                        }
                    }
                }
                people {
                    ... on WpPeople {
                        id
                        title
                        uri
                        peopleContent {
                            summary
                        }
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            layout: CONSTRAINED,
                                            aspectRatio: 1,
                                            width: 70
                                        )
                                    }
                                }
                            }
                        }
                        jobTitles {
                            nodes {
                                name
                            }
                        }
                    }
                }
            }
            podcastInformation {
                applePodcastsLink
                duration
                googlePlayLink
                simplecastEmbed
                spotifyLink
            }
            videoInformation {
                vimeoPlayer
            }
            eventInformation {
                startDate
                endDate
                time
                registerLink
                pricingShort
                pricing
                location
                legalCredits
                instructors
            }
        }
    }
`
