import React from "react";
import {
  socialSharing,
  facebook,
  linkedIn,
  twitter,
} from "./SocialSharing.module.scss"
import StyledIcon from "../Common/StyledIcon";

const SocialSharing = ({ href, title }) => {
  const windowLocation = typeof (window) !== `undefined` ? window.location.href : null;
  const linkUrl = href ?? windowLocation

  const handleClick = (platform) => {
    switch (platform) {
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&title=${title}&url=${linkUrl}&summary=&source=`,
          'share_linkedin',
          'toolbar=0,status=0,resizable=1,width=626,height=600'
        );
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?text=${title}&url=${linkUrl}&via=@procopiolaw`,
          'share_twitter',
          'toolbar=0,status=0,resizable=1,width=626,height=436'
        );
        break;
      case 'facebook':
        window.open(
          `http://www.facebook.com/sharer.php?display=popup&u=${linkUrl}`,
          'share_facebook',
          'toolbar=0,status=0,resizable=1,width=626,height=436'
        );
        break;
    }
  }

  return (
    <div className={socialSharing}>
      <div>
        <button className={linkedIn} onClick={() => handleClick('linkedin')} aria-label="Share on LinkedIn">
          <StyledIcon name="linkedin" />
        </button>
      </div>
      <div>
        <button className={twitter} onClick={() => handleClick('twitter')} aria-label="Share on Twitter">
          <StyledIcon name="twitter" />
        </button>
      </div>
      <div>
        <button className={facebook} onClick={() => handleClick('facebook')} aria-label="Share on Facebook">
          <StyledIcon name="facebook" />
        </button>
      </div>
    </div>
  )
}

export default SocialSharing