import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import StyledImage from '../../../Common/StyledImage'
import Play from '../../../Play'
import SocialSharing from '../../../SocialSharing'
import SectionWrapper from '../../SectionWrapper'
import StyledCard from '../../../Common/StyledCard'
import { bgDark, videoPlayButton, videoHeading } from './Video.module.scss'
import MediaModal from '../../../MediaModal'
import { useUpNextVideos } from '../../../../hooks/use-up-next-videos'
import SubMenu from '../../../FlexibleContent/SubMenu'
import ServiceLabel from '../../../ServiceLabel'
import Button from "../../../Button";
import getVimeoLink from "../../../../helpers/getVimeoLink";
import MediaContact from "../../../FlexibleContent/MediaContact";

// create the play button to pass to HOC
const VideoPlayButton = ({ title, image, onClick }) => {
	return (
		<button
			onClick={onClick}
			type="button"
			aria-label="Watch Video"
			className={videoPlayButton}
		>
			<StyledImage imageData={image} alt={title} />
			<Play size="large" bgColor="transparent" />
		</button>
	)
}

const Video = ({ ...props }) => {
	const {
		id,
		title,
		date,
		machineDate,
		content,
		featuredImage,
		videoInformation,
		locationData,
	} = props
	const prevState = locationData?.state

	const { service, pdf } = props.resourceInformation

	let upNext = useUpNextVideos()
	upNext = upNext.filter(({ video }) => {
		return video.id !== id
	})
	upNext = upNext.slice(0, 3)

	const VideoModal = MediaModal(VideoPlayButton, {
		title: title,
		image: featuredImage?.node?.videoImage?.childImageSharp?.gatsbyImageData,
	})

	const vimeoLink = getVimeoLink(videoInformation?.vimeoPlayer)

	return (
		<>
			<SectionWrapper
				detailClass="video"
				backBtn={{ text: 'Back to Videos', href: prevState?.path ?? '/videos' }}
				menu={<SubMenu location={`resources`} forceActive={`/videos/`} />}
			>
				<Container>
					<Row>
						<Col xs={12} lg={{ offset: 1, span: 10 }}>
							<div className="position-relative">
								<VideoModal
									title={title}
									modalId="video-modal"
									content={
										<div className="ratio ratio-16x9">
											<iframe
												src={vimeoLink}
												title={title}
												allowFullScreen
											/>
										</div>
									}
								/>
							</div>
						</Col>
					</Row>

					<Row>
						<Col
							className="d-none d-lg-block pt-4"
							xl={{ offset: 1, span: 1 }}
							lg={{ offset: 1, span: 2 }}
						>
							<SocialSharing />
						</Col>
						<Col xs={12} lg={8}>
							<div className="text-gray-dark-md article-meta pt-4">
								<ServiceLabel service={service} />
								<time dateTime={machineDate}>{date}</time>
							</div>
							<h1 className={`article-title mt-3 mb-3 ` + videoHeading}>{title}</h1>
							<div
								className="editor-section"
								dangerouslySetInnerHTML={{ __html: content }}
							/>
							{pdf?.localFile?.publicURL && (
								<div className="pb-4">
									<Button href={pdf?.localFile?.publicURL} file={true} theme="white" buttonText="View PDF" />
								</div>
							)}
						</Col>

						<Col xs={12} className="mt-3 d-block d-lg-none">
							<SocialSharing />
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<hr className={bgDark} />
							<h2 className="article-meta">up next</h2>
						</Col>
						{upNext &&
							upNext.map(({ video }, index) => (
								<Col className="pb-5" xs={12} md={4} key={index}>
									<StyledCard
										sectionBg="dark"
										variation="VideoSmall"
										href={video.uri}
										title={video.title}
										image={
											video.featuredImage?.node?.localFile?.childImageSharp
												?.gatsbyImageData
										}
										eventDetails={null}
										imageSize="medium"
										time={video.videoInformation?.duration}
									/>
								</Col>
							))}
					</Row>
					<MediaContact viewType="detail" dark={true} />
				</Container>
			</SectionWrapper>
		</>
	)
}

export default Video
