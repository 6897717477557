import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../../../Link'
import { authorWrapper, bioContent } from './AuthorBio.module.scss'

const AuthorBio = ({ name, title, bio, image, link, ...props }) => {
	let imageData = null
	if (typeof image === 'object' && image !== null) {
		imageData = getImage(image)
	}
	return (
		<div className={authorWrapper}>
			<div className="d-flex align-items-center align-items-md-start">
				<div className="flex-shrink-0">
					{imageData &&
						link ? (
							<Link to={link}>
								<GatsbyImage
									image={imageData}
									alt={name}
									className="rounded-circle"
								/>
							</Link>
						) : (
							<GatsbyImage
								image={imageData}
								alt={name}
								className="rounded-circle"
							/>
						)}
				</div>
				<div className="flex-grow-1 ms-4">
					<h3 className="title--md">
						{link ? (
							<Link to={link}>{name}</Link>
						) : (
							<>{name}</>
						)}
					</h3>

					{title ? (
						<div className="label-small pb-2">
							{title.nodes?.map((job, i, arr) => (
								<div key={job.id}>
									<span>{job.name}</span>
									{arr.length > i + 1 ? <span className="mx-1">&</span> : null}
								</div>
							))}
						</div>
					) : null}
					<div
						className={[
							'd-none',
							'd-md-block',
							'editor-section',
							bioContent,
						].join(' ')}
						dangerouslySetInnerHTML={{ __html: bio }}
					/>
				</div>
			</div>
			<div className="d-flex">
				<div
					className={['d-md-none', 'editor-section', bioContent].join(' ')}
					dangerouslySetInnerHTML={{ __html: bio }}
				/>
			</div>
		</div>
	)
}

export default AuthorBio
