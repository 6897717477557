import { useStaticQuery, graphql } from 'gatsby'

export const useUpNextVideos = () => {
	const { allWpPost } = useStaticQuery(
		graphql`
			query RecentVideos {
				allWpPost(
					limit: 4
					sort: { fields: [date], order: DESC }
					filter: {
						resourceTypes: { nodes: { elemMatch: { slug: { eq: "videos" } } } }
					}
				) {
					edges {
						video: node {
							id
							uri
							date(formatString: "MMMM DD, YYYY")
							title
							featuredImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.59, width: 850)
										}
									}
								}
							}
							videoInformation {
								duration
							}
							resourceInformation {
								service {
									... on WpService {
										title
										uri
										serviceTypes {
											nodes {
												slug
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	)
	return allWpPost?.edges
}
