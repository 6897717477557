import React from 'react'
import clsx from 'clsx'
import Container from 'react-bootstrap/Container'
import IconLink from '../../Common/StyledButton/IconLink'
import {
	backBtnWrapper,
	sectionWrapper,
	video,
	event,
	podcast,
	article,
	menuWrapper,
} from './SectionWrapper.module.scss'

const SectionWrapper = ({
	bgColor,
	backBtn,
	children,
	detailClass,
	menu,
	...props
}) => {
	const detailTypes = { video, event, podcast, article }
	const sectionClasses = clsx(
		sectionWrapper,
		detailClass && detailTypes[detailClass]
	)
	return (
		<section className={sectionClasses}>
			<div className={menuWrapper}>
				{menu}

				<Container fluid="md">
					{backBtn && (
						<div className={backBtnWrapper}>
							<IconLink
								align="right"
								cta={backBtn.text || 'back to articles'}
								href={backBtn.href || '/contact'}
								size="small"
								iconColor={backBtn.color || 'orange'}
								name="left"
							/>
						</div>
					)}
				</Container>
			</div>
			<>{children}</>
		</section>
	)
}

export default SectionWrapper
