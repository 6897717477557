import React, {useState} from 'react'
import {Modal} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* This is Higher-Order Component */
const MediaModal = (ClickElement, clickElementProps) => ({modalId, title, content}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ClickElement onClick={handleShow} {...clickElementProps} />
      {show && (
        <Modal
          fullscreen="sm-down"
          contentClassName="modal-wrapper video-modal-wrapper"
          size="xl"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-90w"
          aria-labelledby={modalId}
        >
          <ModalHeader bsPrefix="modal-header-custom">
            <button onClick={handleClose} className="close" type="button">
              Close
            </button>
            <Row>
              <Col xs={12} sm={8}>
                <Modal.Title id={modalId}>
                  <div dangerouslySetInnerHTML={{ __html: title }}/>
                </Modal.Title>
              </Col>
            </Row>
          </ModalHeader>
          <Modal.Body>
            {content}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default MediaModal