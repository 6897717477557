import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import AuthorBio from '../AuthorBio'
import { authorBioWrapper } from './AuthorBioSection.module.scss'

const AuthorBioSection = ({ authors, ...props }) => {
	
	return (
		<section>
			<Container fluid="md">
				<hr />
				<div className={authorBioWrapper}>
					<Row>
						<Col md={{ offset: 2, span: 8 }}>
							<h2 className="article-meta">About the Authors</h2>
							{authors.map((author, index) => {
								const authorInfo = {
									name: null,
									title: null,
									uri: null,
									image: null,
									bio: null,
								}
								switch (author?.fieldGroupName) {
									case "Post_Resourceinformation_Author_CustomAuthor":
										authorInfo.name = author?.name
										authorInfo.image = author?.authorImage?.localFile?.childImageSharp?.gatsbyImageData
										authorInfo.bio = author?.bio
										break;
									case "Post_Resourceinformation_Author_SystemAuthor":
										authorInfo.name = author?.authorSystemPeople?.title
										authorInfo.title = author?.authorSystemPeople?.jobTitles
										authorInfo.uri = author?.authorSystemPeople?.uri
										authorInfo.image = author?.authorSystemPeople?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
										authorInfo.bio = author?.authorSystemPeopleBio ?? author?.authorSystemPeople?.peopleContent?.summary
										break;
								}
								return (
								<AuthorBio
									key={'author-' + index}
									name={authorInfo?.name}
									title={authorInfo?.title}
									link={authorInfo?.uri}
									image={
										authorInfo?.image
									}
									bio={authorInfo?.bio}
								/>
							)})}
						</Col>
					</Row>
				</div>
			</Container>
		</section>
	)
}

export default AuthorBioSection
