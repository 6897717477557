import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SocialSharing from '../../../../SocialSharing'
import Button from "../../../../Button";
import { articlePost, postContent } from './ArticlePost.module.scss'

const ArticlePost = ({ content, pdf, ...props }) => {
	return (
		<div className={articlePost}>
			<Container fluid="md">
				<Row>
					<Col className="d-none d-md-block" md={2} lg={{ span: 1, offset: 1 }}>
						<SocialSharing />
					</Col>
					<Col xs={12} md={8}>
						<div
							dangerouslySetInnerHTML={{ __html: content }}
							className={`editor-section ` + postContent}
						/>
						{pdf?.localFile?.publicURL && (
							<Button href={pdf?.localFile?.publicURL} file={true} theme="gray" buttonText="View PDF" />
						)}
					</Col>
					<Col xs={12} className="mt-3 d-block d-md-none">
						<SocialSharing />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ArticlePost
