// extracted by mini-css-extract-plugin
export var article = "SectionWrapper-module--article--1pr3m";
export var menuWrapper = "SectionWrapper-module--menuWrapper--hftJl";
export var backBtnWrapper = "SectionWrapper-module--back-btn-wrapper--tnzqL";
export var sectionWrapper = "SectionWrapper-module--section-wrapper--20NHa";
export var video = "SectionWrapper-module--video--190Yq";
export var ctaWrapper = "SectionWrapper-module--cta-wrapper--10KZB";
export var heading = "SectionWrapper-module--heading--1QzNK";
export var event = "SectionWrapper-module--event--23vVx";
export var podcast = "SectionWrapper-module--podcast--1nCYp";
export var btn = "SectionWrapper-module--btn--19OZy";