import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from 'react-bootstrap'
import {
	featuredImage,
	authorWrapper,
	headerWrapper,
	titleWrapper,
	imageInner,
	keylineTop,
	heroWrapper,
	headerLangSelect,
} from './Hero.module.scss'
import ByLine from '../../ByLine'
import ServiceLabel from "../../../../ServiceLabel";
import LanguageSelector from "../../../../LanguageSelector";

const Hero = ({
	service,
	authors,
	date,
	machineDate,
	title,
	image,
	backBtn,
	langSelect,
	langLocale,
	...props
}) => {
	let imageData = null
	if (typeof image === 'object' && image !== null) {
		imageData = getImage(image)
	}
	return (
		<div className={heroWrapper}>
			<Container fluid="md">
				<Row>
					<Col
						xs={{ span: 12, order: 2 }}
						md={{ span: 6, order: 1 }}
						lg={{ span: 5, order: 1, offset: 1 }}
					>
						<header className={headerWrapper}>
							<div className="article-meta article-meta--gray">
								{langSelect && (
									<div className={headerLangSelect}>
										<LanguageSelector translations={langSelect} locale={langLocale} />
									</div>
								)}
								<ServiceLabel service={service} />
								<time dateTime={machineDate}>{date}</time>
							</div>
							<h1
								className={[
									'article-title',
									'd-none',
									'd-md-block',
									titleWrapper,
								].join(' ')}
							>
								{title}
							</h1>

							<div className={authorWrapper}>
								{authors && (
									<>
										<div className={keylineTop} />
										<div className="article-author">
											<ByLine authors={authors} />
										</div>
									</>
								)}
							</div>
						</header>
					</Col>

					<Col
						className="p-0 position-relative"
						xs={{ span: 12, order: 1 }}
						md={{ span: 6, order: 2 }}
					>
						<h1
							className={[
								'article-title',
								'd-block',
								'd-md-none',
								titleWrapper,
							].join(' ')}
						>
							{title}
						</h1>

						{imageData && (
							<div className={imageInner}>
								<GatsbyImage
									layout="constrained"
									image={imageData}
									alt={title}
									className={featuredImage}
								/>
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Hero
