import { useStaticQuery, graphql } from 'gatsby'

export const useUpNextPodcasts = () => {
	const { allWpPost } = useStaticQuery(
		graphql`
			query RecentPodcasts {
				allWpPost(
					limit: 5
					sort: { fields: [date], order: DESC }
					filter: {
						resourceTypes: { nodes: { elemMatch: { slug: { eq: "podcasts" } } } }
					}
				) {
					edges {
						podcast: node {
							id
							uri
							date(formatString: "MMMM DD, YYYY")
							title
							categories {
								nodes {
									name
									uri
								}
							}
							featuredImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												aspectRatio: 1
												layout: CONSTRAINED
												width: 270
											)
										}
									}
								}
							}
							podcastInformation {
								duration
							}
						}
					}
				}
			}
		`
	)
	return allWpPost?.edges
}
