import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import StyledImage from '../../../Common/StyledImage'
import SocialSharing from '../../../SocialSharing'
import SectionWrapper from '../../SectionWrapper'
import StyledCard from '../../../Common/StyledCard'
import IconBtn from '../../../Common/StyledButton/IconBtn'
import MediaModal from '../../../MediaModal'
import ByLine from '../ByLine'
import SubMenu from '../../../FlexibleContent/SubMenu'
import { useUpNextPodcasts } from '../../../../hooks/use-up-next-podcasts'

import {
	contentWrapper,
	light,
	mainContent,
	mediaBtns,
} from './Podcast.module.scss'
import ServiceLabel from "../../../ServiceLabel";
import Button from "../../../Button";
import MediaContact from "../../../FlexibleContent/MediaContact";

// create the play button to pass to HOC
const PodcastPlayButton = ({ onClick }) => {
	return <IconBtn variant="playNow" onClick={onClick} />
}

const Podcast = ({ ...props }) => {
	const {
		id,
		title,
		date,
		machineDate,
		content,
		featuredImage,
		podcastInformation,
		categories,
		locationData,
	} = props
	const prevState = locationData?.state

	const { service, author, pdf } = props.resourceInformation
	const authors = author
	const PodcastModal = MediaModal(PodcastPlayButton)
	const {
		applePodcastsLink,
		spotifyLink,
		googlePlayLink,
	} = podcastInformation

	let upNext = useUpNextPodcasts()
	upNext = upNext.filter(({ podcast }) => {
		return podcast.id !== id
	})
	upNext = upNext.slice(0, 4)

	return (
		<>
			<SectionWrapper
				detailClass="podcast"
				backBtn={{ text: 'Back to Podcasts', href: prevState?.path ?? '/podcasts' }}
				menu={<SubMenu location={`resources`} forceActive={`/podcasts/`} />}
			>
				<Container>
					<div className={mainContent}>
						<Row>
							<Col className="d-none d-md-block pt-0" md={1}>
								<SocialSharing />
							</Col>

							<Col xs={12} className="pt-0" md={5} lg={4}>
								<h1 className="article-title d-md-none d-block pb-4">
									{title}
								</h1>
								<StyledImage
									variant="large-square"
									imageData={
										featuredImage?.node?.podcastImage?.childImageSharp
											?.gatsbyImageData
									}
									alt={title}
								/>
							</Col>
							<Col xs={12} md={6} lg={7}>
								<span className="mt-4 mb-4 d-block d-md-none">
									<PodcastModal
										title={title}
										modalId="podcast-modal-mobile"
										content={
											<div
												className="editor-section"
												dangerouslySetInnerHTML={{
													__html: podcastInformation?.simplecastEmbed,
												}}
											/>
										}
									/>
								</span>

								<div className="text-gray-dark-md article-meta">
									<ServiceLabel service={service} />
									<time dateTime={machineDate}>{date}</time>
									{podcastInformation?.duration
										? ` | ${podcastInformation?.duration}`
										: null}
								</div>

								<div className="article-title d-none d-md-block">{title}</div>
								<div className="article-author">
									<ByLine authors={authors} />
								</div>
								<div
									className={`editor-section ` + contentWrapper}
									dangerouslySetInnerHTML={{ __html: content }}
								/>
								{pdf?.localFile?.publicURL && (
									<div className="pb-4">
										<Button href={pdf?.localFile?.publicURL} file={true} theme="gray" buttonText="View PDF" />
									</div>
								)}
								<span className="d-md-block d-none">
									<PodcastModal
										title={title}
										modalId="podcast-modal-desktop"
										content={
											<div
												className="editor-section"
												dangerouslySetInnerHTML={{
													__html: podcastInformation?.simplecastEmbed,
												}}
											/>
										}
									/>
								</span>
								<span className="d-block d-md-none">
									<SocialSharing />
								</span>
								<hr className={light} />
								{applePodcastsLink || spotifyLink || googlePlayLink ? (
									<div className="d-none d-md-block">
										{applePodcastsLink &&
										<IconBtn
											variant="Apple"
											cta="Listen on Apple Podcasts"
											href={applePodcastsLink}
										/>
										}

										{spotifyLink &&
										<IconBtn
											variant="Spotify"
											cta="Listen on Spotify"
											href={spotifyLink}
										/>
										}

										{googlePlayLink &&
										<IconBtn
											variant="Google"
											cta="Listen on Google"
											href={googlePlayLink}
										/>
										}
									</div>
								) : null }

								{applePodcastsLink || spotifyLink || googlePlayLink ? (
									<div className={[mediaBtns, 'd-block', 'd-md-none'].join(' ')}>
										<h3 className="article-meta">Listen On</h3>
										{applePodcastsLink &&
											<IconBtn
												variant="Apple"
												cta="Apple Podcasts"
												href={applePodcastsLink}
											/>
										}
										{spotifyLink &&
											<IconBtn
												variant="Spotify"
												cta="Spotify"
												href={spotifyLink}
											/>
										}
										{googlePlayLink &&
											<IconBtn
												variant="Google"
												cta="Google"
												href={googlePlayLink}
											/>
										}
									</div>
								) : null}
							</Col>
						</Row>
					</div>

					<MediaContact viewType="list" />

					<Row>
						<Col xs={12}>
							<h2 className="article-meta pt-4">up next</h2>
						</Col>

						{upNext &&
							upNext.map(({ podcast }, index) => {
								return (
									<Col xs={6} md={3} key={index}>
										<StyledCard
											key={index}
											headingSize="title--sm"
											variation="CardF"
											service={podcast?.resourceInformation?.service}
											label={null}
											imageSize="medium-square"
											play={true}
											href={podcast.uri}
											title={podcast.title}
											image={
												podcast.featuredImage?.node?.localFile?.childImageSharp
													?.gatsbyImageData
											}
											eventDetails={null}
											time={podcast.podcastInformation?.duration}
										/>
									</Col>
								)
							})}
					</Row>
				</Container>
			</SectionWrapper>
		</>
	)
}

export default Podcast
