import React, {Fragment} from "react"
import Link from "../../../Link";

const ByLine = ({authors}) => {
  if (!authors) return null;

  return (
    <>
      {'by '}
      {authors.map((author, index) => {
        const authorInfo = {
          uri: null,
          name: null,
        }
        switch (author?.fieldGroupName) {
          case "Post_Resourceinformation_Author_CustomAuthor":
            authorInfo.name = author?.name
            break;
          case "Post_Resourceinformation_Author_SystemAuthor":
            authorInfo.name = author?.authorSystemPeople?.title
            authorInfo.uri = author?.authorSystemPeople?.uri
            break;
        }
        return (
          <Fragment key={`byline-${index}`}>
            {authorInfo.uri ? (
              <Link to={authorInfo.uri}>{authorInfo.name}</Link>
            ) : (
              <>{authorInfo.name}</>
            )}

            {authors.length > 2 && index + 2 !== authors.length && index + 1 !== authors.length
              ? ', '
              : authors.length > 1 && index !== authors.length - 1
                ? ' and '
                : ''}
          </Fragment>
        )
      })}
    </>
  )
}

export default ByLine