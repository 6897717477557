import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import EventDate from '../../../../helpers/EventDate'
import StyledImage from '../../../Common/StyledImage'
import SocialSharing from '../../../SocialSharing'
import SectionWrapper from '../../SectionWrapper'
import Button from '../../../Button'
import SubMenu from '../../../FlexibleContent/SubMenu'
import { date, num, eventTitle, light, registerBtn } from './Event.module.scss'
import MediaContact from "../../../FlexibleContent/MediaContact";

const Event = ({ details, locationData, ...props }) => {
	const prevState = locationData?.state
	const { title, content, eventInformation, featuredImage } = props

	const eventStartDate = eventInformation?.startDate ? new EventDate(eventInformation?.startDate) : null
	const eventEndDate = eventInformation?.endDate
		? new EventDate(eventInformation.endDate)
		: null

	const activeSubNav = prevState?.path && prevState?.path.includes('past-events') ? '/past-events/' : '/events/'

	return (
		<>
			<SectionWrapper
				detailClass="event"
				backBtn={{ text: 'Back to Events', href: prevState?.path ?? '/events' }}
				menu={<SubMenu location={`events`} forceActive={activeSubNav} />}
			>
				<Container>
					<Row className="pb-5">
						<Col className="p-md-0" xs={12} md={7}>
							<div className="article-meta d-block d-md-none mb-1">
								{eventInformation.pricingShort}
							</div>
							<div
								className={[eventTitle, 'd-block', 'd-md-none', 'mb-3'].join(
									' '
								)}
							>
								{title}
							</div>
							{/* add date  */}
							<StyledImage
								variant="xlarge"
								imageData={
									featuredImage?.node?.eventImage?.childImageSharp
										?.gatsbyImageData
								}
								alt={title}
							/>
							<Row>
								<Col
									className="d-flex justify-content-center d-md-none pt-5"
									xs={12}
								>
									{/* for button theme and size options, refer to the button component */}
									<Button
										buttonText="Register"
										to={eventInformation.registerLink}
										size={'large'}
									/>
								</Col>
								<Col className="d-none d-lg-block pt-5" lg={1}>
									<SocialSharing />
								</Col>

								<Col
									className="pb-lg-5 ps-lg-5 pt-md-5"
									xs={12}
									lg={10}
								>
									<div className="editor-section" dangerouslySetInnerHTML={{ __html: content }} />

									{props.resourceInformation?.pdf?.localFile?.publicURL && (
										<Button href={props.resourceInformation?.pdf?.localFile?.publicURL} file={true} theme="gray" buttonText="View PDF" />
									)}
								</Col>

								<Col className="d-block d-lg-none pt-5" xs={12}>
									<SocialSharing />
									<hr className={light} />
								</Col>
							</Row>
						</Col>

						<Col className="p-md-0" md={5}>
							<div className="ps-md-4 ps-lg-5 ms-md-4 ms-lg-5 ps-0 ms-0 section-border-left">
								<Row className="d-none d-md-flex">
									<Col className="ms-0 p-0 pe-xl-3 col-xl-3" md={4} lg={3}>
										<time className={date} dateTime={eventInformation?.startDate}>
											<span className={num}>{eventStartDate?.date}</span>
											<span className="label-small">
												{eventStartDate?.monthShort}
											</span>
										</time>
									</Col>
									<Col className="me-xl-4 pe-xl-4 col-md-8" md={8} xl={8}>
										<div className="article-meta">
											{eventInformation.pricingShort}
										</div>
										<div className={eventTitle}>{title}</div>
									</Col>
								</Row>
								<Row>
									<Col className="d-none d-md-block p-0" md={12}>
										<div className={registerBtn}>
											<hr className={light} />
											{/* for button theme and size options, refer to the button component */}
											<Button
												buttonText="Register"
												to={eventInformation.registerLink}
												size={'large'}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<h4 className="label-small mt-4">Date & Time </h4>
										<div>
											<time dateTime={eventInformation?.startDate}>
												{`${eventStartDate?.dayOfWeek}, ${eventStartDate?.monthLong} ${eventStartDate?.date}`}
												{eventEndDate?.date
													? ` – ${
															eventEndDate?.monthLong != eventStartDate?.monthLong
																? eventEndDate?.monthLong
																: ''
														} ${eventEndDate?.date}`
													: ''}
												<br />
												{eventInformation?.time}
											</time>
										</div>

										{eventInformation.location && (
											<>
												<h4 className="label-small mt-4">Location</h4>
												<div
													className="editor-section"
													dangerouslySetInnerHTML={{
														__html: eventInformation.location,
													}}
												/>
											</>
										)}

										{eventInformation.pricing && (
											<>
												<h4 className="label-small mt-4">Pricing</h4>
												<div
													className="editor-section"
													dangerouslySetInnerHTML={{
														__html: eventInformation.pricing,
													}}
												/>
											</>
										)}

										{eventInformation.instructors && (
											<>
												<h4 className="label-small mt-4">Presenters</h4>
												<div
													className="editor-section"
													dangerouslySetInnerHTML={{
														__html: eventInformation.instructors,
													}}
												/>
											</>
										)}

										{eventInformation.legalCredits && (
											<>
												<h4 className="label-small mt-4">Legal Credits</h4>
												<div
													className="editor-section"
													dangerouslySetInnerHTML={{
														__html: eventInformation.legalCredits,
													}}
												/>
											</>
										)}
									</Col>
								</Row>
							</div>
						</Col>
					</Row>

					<MediaContact viewType="detail" />
				</Container>
			</SectionWrapper>
		</>
	)
}

export default Event
