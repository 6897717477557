import React from 'react'
import {Container} from 'react-bootstrap'
import Hero from './Hero'
import ArticlePost from './ArticlePost'
import AuthorBioSection from './AuthorBioSection'
import SubMenu from "../../../FlexibleContent/SubMenu";
import SectionWrapper from "../../SectionWrapper"
import RelatedContent from "../../RelatedContent";
import {useRelatedResources} from '../../../../hooks/use-related-resources'
import {useResourcesByService} from "../../../../hooks/use-resources-by-service";
import {
	articlesRelatedContent,
} from './articleDetail.module.scss'
import MediaContact from "../../../FlexibleContent/MediaContact";

const Article = ({ details, locationData, ...props }) => {
  const prevState = locationData?.state
  const {
    id,
    title,
    date,
    machineDate,
    content,
    featuredImage,
    categories,
    translations,
    locale,
  } = props

  const { service, author, pdf } = props.resourceInformation
  const authors = author

  let related

  if (service?.length && service[0]?.slug) {
    related = useResourcesByService(service[0]?.slug)
  } else {
    related = useRelatedResources()
    related = related.filter(({resource}) => {
      return resource.id !== id
    })
  }

  const backLink = prevState?.path ?? `/${props.resourceTypes.nodes[0].slug}/`

  return (
    <>
      <SectionWrapper
        detailClass="article"
        backBtn={{ text: `Back to ${props.resourceTypes.nodes[0].name}`, href: backLink }}
        menu={<SubMenu location={`resources`} forceActive={`/${props.resourceTypes.nodes[0].slug}/`} />}
      >
        <Hero
          service={service}
          authors={authors}
          langSelect={translations}
          langLocale={locale.locale}
          date={date}
          machineDate={machineDate}
          image={featuredImage?.node?.articleImage?.childImageSharp?.gatsbyImageData}
          title={title}
        />
        <ArticlePost content={content} pdf={pdf} />
        {authors &&
          <AuthorBioSection authors={authors} />
        }
        <Container fluid="md">
          <MediaContact viewType="detail" />
        </Container>

        <div className={articlesRelatedContent}>
          <RelatedContent resources={related} showButton={false} buttonPos={'top'} />
        </div>
      </SectionWrapper>
    </>
  )
}

export default Article
